export enum DocumentDiscipline {
  ACCOUNTANCY = "accountancy",
  FISCAAL = "fiscaal",
  PERSONEEL = "personeel",
  CORPORATE_FINANCE = "corporate_finance",
  SUBSIDIEADVIES = "subsidieadvies",
  FAMILIEADVIES = "familieadvies",
  IT_ADVIES = "it_advies",
  FINANCIERINGSADVIES = "financieringsadvies",
  BEDRIJFSADVIES = "bedrijfsadvies",
}
